@import '../../styles/utils';

.PdfMatches {
  height: 100vh;

  .screen-content {
    margin-bottom: 0;
  }

  &__container {
    display: flex;
    flex-flow: column;
    overflow: hidden;
    margin-bottom: 1rem;
    max-width: 768px;

    @include media-desktop() {
      max-width: none;
    }
  }

  &__header {
    display: flex;
    align-items: flex-start;
    flex-shrink: 0;

    @include media-desktop() {
      position: absolute;
      top: rem(15);
      right: rem(50);
    }

    @media screen and (max-width: 769px) and (min-width: 320px) {
      padding: 0.5rem 1rem;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.22);
    }
  }

  &__search-results {
    overflow-y: scroll;
    flex: 1;
    list-style: none;

    @media screen and (max-width: 769px) and (min-width: 320px) {
      padding: 0 1rem;
    }
  }

  &__search-result {
    padding: 0.5rem 0 0.5rem 0;
    color: #495360;
    transition: all 0.2s;
    font-size: 1rem;
    font-weight: 300;
    cursor: pointer;

    &:hover {
      > div {
        color: #666;
      }
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid #cfdbe6;
    }
  }

  &__search-result-page {
    font-size: 12px;
    color: #61666b;

    @include media-desktop() {
      color: #495360;
      opacity: 0.5;
    }
  }

  &__search-result-match {
    line-height: 1.2rem;
    color: #61666b;
    font-size: 16px;

    em {
      font-weight: bold;
    }
  }
}
