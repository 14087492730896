@import '../utils';
@import 'infiniti_variables';

/* BRAND FONTS */

@font-face {
  font-family: 'infiniti_brand';
  // IE9 Compat Modes
  src: url('../fonts/infinitibrand-bold-webfont.eot');
  // IE6-IE8
  src: url('../fonts/infinitibrand-bold-webfont.eot?#iefix') format('embedded-opentype'),
    // Super Modern Browsers
    url('../fonts/infinitibrand-bold-webfont.woff2') format('woff2'),
    // Modern Browsers
    url('../fonts/infinitibrand-bold-webfont.woff') format('woff'),
    // Safari, Android, iOS
    url('../fonts/infinitibrand-bold-webfont.ttf') format('truetype'),
    // Legacy iOS
    url('../fonts/infinitibrand-bold-webfont.svg#infiniti_brandbold') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'infiniti_brand';
  // IE9 Compat Modes
  src: url('../fonts/infinitibrand-bold-webfont.eot');
  // IE6-IE8
  src: url('../fonts/infinitibrand-bold-webfont.eot?#iefix') format('embedded-opentype'),
    // Super Modern Browsers
    url('../fonts/infinitibrand-bold-webfont.woff2') format('woff2'),
    // Modern Browsers
    url('../fonts/infinitibrand-bold-webfont.woff') format('woff'),
    // Safari, Android, iOS
    url('../fonts/infinitibrand-bold-webfont.ttf') format('truetype'),
    // Legacy iOS
    url('../fonts/infinitibrand-bold-webfont.svg#infiniti_brandbold') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'infiniti_brand';
  // IE9 Compat Modes
  src: url('../fonts/infinitibrand-regular-webfont.eot');
  // IE6-IE8
  src: url('../fonts/infinitibrand-regular-webfont.eot?#iefix') format('embedded-opentype'),
    // Super Modern Browsers
    url('../fonts/infinitibrand-regular-webfont.woff2') format('woff2'),
    // Modern Browsers
    url('../fonts/infinitibrand-regular-webfont.woff') format('woff'),
    // Safari, Android, iOS
    url('../fonts/infinitibrand-regular-webfont.ttf') format('truetype'),
    // Legacy iOS
    url('../fonts/infinitibrand-regular-webfont.svg#infiniti_brandregular') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'infiniti_brand';
  // IE9 Compat Modes
  src: url('../fonts/infinitibrand-light-webfont.eot');
  // IE6-IE8
  src: url('../fonts/infinitibrand-light-webfont.eot?#iefix') format('embedded-opentype'),
    // Super Modern Browsers
    url('../fonts/infinitibrand-light-webfont.woff2') format('woff2'),
    // Modern Browsers
    url('../fonts/infinitibrand-light-webfont.woff') format('woff'),
    // Safari, Android, iOS
    url('../fonts/infinitibrand-light-webfont.ttf') format('truetype'),
    // Legacy iOS
    url('../fonts/infinitibrand-light-webfont.svg#infiniti_brandlight') format('svg');
  font-weight: 300;
  font-style: normal;
}

// =========================
// Infiniti specific styling
// =========================

.expand-image-modal.Infiniti {
  font-family: 'infiniti_brand', sans-serif;
}

body:has(#root > .infiniti) {
  background-color: $infiniti-gold;
}

div.infiniti {
  background-color: $infiniti-gold;

  .screen-container,
  .screen-head {
    background-color: $infiniti-gold;
  }

  & *,
  .topic-content .note * {
    font-family: 'infiniti_brand', sans-serif;
    font-weight: 300;
  }

  .topic-content {
    p,
    .p,
    li,
    strong {
      font-family: 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
    }

    p.grid a {
      border-color: $infiniti-light-gray;
    }
  }

  .screen-logo,
  .terms-logo {
    width: rem(88);
    height: rem(39);
  }

  .terms-logo {
    flex: 0 0 rem(84);

    @include media-desktop {
      flex: 0 0 rem(95);
    }
  }

  @include media-desktop {
    .screen-logo {
      margin: 0 rem(20) 0 0;
    }
  }

  // Splash screen colors
  .Vehicles,
  .BrandsSelectorScreen {
    color: $infiniti-primary-color;
    .hint,
    .terms {
      color: $infiniti-primary-color;
    }
    @include media-desktop() {
      .screen-title {
        font-weight: normal;
      }
    }
  }

  // Regular screen colors
  .Guide {
    color: $infiniti-contrast-color;

    @include media-desktop() {
      .screen-title {
        color: $infiniti-primary-color;
        font-size: rem(26);
        text-transform: uppercase;
        font-weight: 300;
      }
    }
  }

  .Loader {
    .loader-default {
      & > div {
        background-color: $infiniti-primary-color;
      }
    }

    .loader-ios {
      @keyframes spinner-ios-infiniti {
        0% {
          background-color: $infiniti-primary-color;
        }
        100% {
          background-color: transparent;
        }
      }
      & > div {
        animation-name: spinner-ios-infiniti;
      }
    }

    .loader-android {
      circle {
        stroke: $infiniti-primary-color;
      }
    }

    &.inverse {
      color: $infiniti-contrast-color;
    }
  }

  .VehicleList {
    h2 {
      color: $infiniti-contrast-color;
    }
    .model {
      color: $infiniti-contrast-color;
      .name {
        color: $infiniti-contrast-color;
      }
    }
  }
  .VehicleModelSelector {
    .model-group {
      text-transform: uppercase;
    }
  }

  .VehicleYearSelector {
    .wrapper > a {
      &.current {
        color: $infiniti-primary-color;

        &:after {
          background: $infiniti-primary-color;
        }
      }
    }
  }

  //Folder filters desktop/mobile
  .FoldersFilter li.active {
    color: $infiniti-primary-color;

    .icon .stroke {
      stroke: $infiniti-primary-color;
    }
  }
  .FoldersFilterDropdown {
    .dropdown-trigger {
      color: $infiniti-primary-color;
      font-weight: 800;

      .icon .stroke {
        stroke: $infiniti-primary-color;
      }
    }
    &.opened .dropdown-trigger {
      box-shadow: inset 0 -1px #e5e5e5;
    }
  }

  // Buttons
  .PrimaryButton,
  .SecondaryButton {
    color: $infiniti-primary-color;
    background: transparent;
    font-weight: 300;
    text-transform: uppercase;
    border: 1px solid $infiniti-primary-color;
    border-radius: 0;
    font-size: rem(16);
    padding: rem(15) rem(20);
  }

  .ErrorMessage {
    /*.PrimaryButton {
      color: $infiniti-secondary-color;
      background: $infiniti-contrast-color;
    }*/
  }

  .ErrorMessage:not(.inverse) {
    background-color: $infiniti-gold;
    color: $infiniti-contrast-color;
    .icon .fill {
      fill: rgba($infiniti-contrast-color, 0.3);
    }
  }

  .Topics {
    @include media-desktop {
      .topic-title {
        font-size: rem(34);
        text-transform: none;
        font-weight: 300;
      }
    }

    .topics-breadcrumb {
      color: $infiniti-contrast-color;
      font-weight: normal;
      background-color: $infiniti-gold;

      li:last-child,
      li:last-child a {
        font-weight: 700;
      }
    }
  }

  .Explore {
    h2 {
      font-weight: 700;
      margin-bottom: rem(10);
    }

    .visual-search-wrapper {
      .TopicsList li {
        margin-bottom: 0;
      }

      .topics-controls {
        &__prev {
          margin-right: rem(20);
        }

        .icon {
          background-color: transparent;
          width: rem(20);
          height: rem(20);
          margin-top: rem(28);
          padding: 0;
        }
      }
    }

    .visual-search {
      .hot-spot {
        background-color: $infiniti-signal-error;
      }

      .image-map-container .image-map-selector {
        button {
          border-color: $infiniti-gray;

          @include media-desktop() {
            height: rem(130);
            padding-bottom: rem(28);
          }

          &:not(.active) {
            background-color: $infiniti-gold;
          }

          .icon {
            width: rem(55);

            &.icon-seatInfiniti {
              width: rem(40);
            }
          }
        }
      }
    }
  }

  .TopicsList {
    &.publications {
      @include media-mobile() {
        li .item-iconbar .icon {
          width: rem(22);

          .fill {
            fill: $infiniti-primary-color;
          }
        }
      }
    }
  }

  .SearchInput {
    & + .topics-subtitle {
      text-transform: uppercase;
      font-size: rem(18);
      font-weight: 700;

      hr {
        display: none;
      }
    }

    .icon-close {
      width: rem(45);

      .stroke {
        stroke: $infiniti-primary-color;
      }
    }

    .icon.icon-cancel {
      .fill {
        fill: #495360;
        opacity: 0.5;
      }
    }
  }

  .PdfMatches {
    &__search-result {
      &-page {
        font-size: rem(13);
        color: $infiniti-contrast-color;
        opacity: 1;
      }

      &:hover {
        > div {
          color: $infiniti-contrast-color;
          font-weight: 400;
        }
      }
    }

    &__search-result-match {
      color: $infiniti-contrast-color;

      em {
        font-weight: 700;
      }
    }
  }
}
