@import '../utils';

// Nissan brand theme
$generic-primary-color: #454f56;
$generic-secondary-color: #fff;
$nissan-contrast-color: #000;
$nissan-background-color: #f2f2f2;
$generic-logo: '../../images/generic_logo.png';

// =========================
// No-brand specific styling
// TODO: move other default styling into components
// =========================

div.generic {
  .logo {
    background-size: contain;
  }

  .screen-logo,
  .terms-logo {
    background-image: url($generic-logo);
  }

  .screen-logo {
    width: rem(75);
    height: rem(75);
  }
  .terms-logo {
    width: rem(55);
    height: rem(55);
  }
  @include media-desktop {
    .terms-logo {
      width: rem(76);
      height: rem(76);
    }
    .terms-title {
      margin-top: rem(38);
    }
    .terms-version {
      margin-top: rem(20);
    }
  }

  // Splash screen colors
  .Vehicles,
  .BrandsSelectorScreen {
    background: $generic-primary-color;
    color: $generic-secondary-color;
    /*.hint {
      color: #E57593;
    }*/
    .screen-title {
      color: $nissan-contrast-color;
    }

    @include media-mobile() {
      .screen-head {
        background-color: transparent;
        box-shadow: none;
      }
      .screen-title,
      .screen-back > * {
        color: $generic-secondary-color;
      }
      .screen-back .icon .stroke {
        stroke: $generic-secondary-color;
      }
    }
  }

  // Regular screen colors
  .Guide {
    color: $nissan-contrast-color;
    background: $generic-secondary-color;
  }

  //loader styling
  .Loader {
    background-color: $generic-primary-color;

    .loader-default {
      & > div {
        background-color: $generic-secondary-color;
      }
    }
    .loader-ios {
      @keyframes spinner-ios-nissan {
        0% {
          background-color: $generic-secondary-color;
        }
        100% {
          background-color: transparent;
        }
      }
      @keyframes spinner-ios-nissan-inverse {
        0% {
          background-color: $generic-primary-color;
        }
        100% {
          background-color: transparent;
        }
      }
      & > div {
        animation-name: spinner-ios-nissan;
      }
    }

    .loader-android {
      circle {
        stroke: $generic-secondary-color;
      }
    }

    &.inverse {
      background-color: $generic-secondary-color;
      color: $nissan-contrast-color;

      .loader-default > div {
        background-color: $generic-primary-color;
      }
      .loader-ios > div {
        animation-name: spinner-ios-nissan-inverse;
      }
      .loader-android {
        circle {
          stroke: $generic-primary-color;
        }
      }
    }
  }

  .VehicleList {
    background: $generic-secondary-color;
    h2 {
      color: $nissan-contrast-color;
    }
    .model {
      color: $nissan-contrast-color;
      .name {
        color: $nissan-contrast-color;
      }
    }
  }

  //Folder filters desktop/mobile
  .FoldersFilter {
    li.active {
      color: $generic-primary-color;

      .icon .stroke {
        stroke: $generic-primary-color;
      }
    }
  }
  .FoldersFilterDropdown {
    .dropdown-trigger {
      background-color: $generic-primary-color;
      color: $generic-secondary-color;
      box-shadow: inset 0 -1px rgba($generic-secondary-color, 0.3);

      .icon .stroke {
        stroke: $generic-secondary-color;
      }
    }
    .dropdown-container {
      background-color: $generic-primary-color;
      li {
        color: $generic-secondary-color;
      }
    }
  }

  // Buttons
  .PrimaryButton {
    color: $nissan-contrast-color;
    background: $generic-secondary-color;
  }
  .SecondaryButton {
    color: $generic-secondary-color;
    background: $generic-primary-color;
    border: 1px solid rgba($generic-secondary-color, 0.3);
    font-weight: 400;
  }

  .ErrorMessage.inverse {
    .PrimaryButton {
      color: $generic-secondary-color;
      background: $generic-primary-color;
    }
  }

  .ErrorMessage:not(.inverse) {
    background-color: $generic-primary-color;
    color: $generic-secondary-color;
    .icon .fill {
      fill: rgba($generic-secondary-color, 0.5);
    }
    h1 {
      color: $generic-secondary-color;
    }
    span {
      color: rgba($generic-secondary-color, 0.5);
    }
  }

  .Topics {
    @include media-mobile {
      .topic-title {
        font-weight: bold;
      }
    }
  }
}
