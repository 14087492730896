@import '../../styles/brands/infiniti_variables.scss';
@import '../../styles/utils';

.InfinitiTopicsList {
  li {
    border-bottom: 1px solid $infiniti-gray;

    .item-inner {
      display: flex;
      flex-flow: column;
      padding-bottom: rem(20);
      padding-top: rem(20);

      &:hover {
        .item-title {
          font-weight: 400;
        }
      }

      &.with-label {
        padding-top: rem(11);
        padding-bottom: rem(11);
      }

      .item-iconbar {
        align-self: flex-start;
        align-items: center;

        .icon {
          margin: 0;
        }

        .label-title {
          color: $infiniti-dark-gray;
          font-size: rem(13);
          font-weight: 300;
          margin-left: rem(9);
        }
      }

      .item-title {
        color: $infiniti-contrast-color;
        padding-left: 0;
        padding-right: rem(25);
        font-size: rem(15);
        transition: none;
        line-height: rem(21);
      }

      .icon {
        svg {
          width: rem(18);
          height: rem(18);
        }

        &.icon-arrow-left {
          position: absolute;
          width: rem(8);
          height: rem(14);
          right: rem(15);
          top: 50%;
          margin-top: rem(-7);
          transform: rotate(180deg);

          .stroke {
            stroke: $infiniti-contrast-color;
            stroke-width: 1;
          }
        }
      }
    }
  }
}
