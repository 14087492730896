@import '../../../styles/utils';

$search-panel-width: 250px;
$search-panel-width-desktop: 410px;

.SearchResults {
  width: $search-panel-width;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  z-index: 2;
  transform: translateX($search-panel-width);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.22);
  background-color: #fff;
  display: flex;
  flex-flow: column;
  transition: transform 0.2s ease-in;

  @include media-desktop() {
    width: $search-panel-width-desktop;
    transform: translateX($search-panel-width-desktop);
    padding-left: rem(10);
    padding-right: rem(22);
  }

  &--open {
    transform: translateX(0);
  }

  > ul {
    padding: 0;
    margin: 0;
  }

  &__header {
    padding: 12px;
    display: flex;
    align-items: center;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.22);
    justify-content: space-between;
    display: flex;

    @include media-desktop() {
      flex-flow: row-reverse;
      box-shadow: none;
    }

    > svg {
      height: 24px;
      width: 24px;
    }

    .SortSelector .Menu {
      right: 12px;

      @media screen and (max-width: 769px) and (min-width: 320px) {
        right: 12px;
      }
    }
  }

  &__items {
    flex: 1;
    overflow: scroll;
  }

  &__item {
    cursor: pointer;
    padding: 12px;
    border: 1px solid rgba(0, 0, 0, 0.22);
    margin: 12px;
    font-size: 10px;
    line-height: 1.2;

    @include media-desktop() {
      font-size: rem(16);
      line-height: rem(22);
    }

    &--selected {
      border: 1px solid #0071c9;
    }

    &-page {
      margin-bottom: 4px;

      @include media-desktop() {
        font-size: rem(14);
      }
    }

    &-content {
      color: #000;

      em {
        font-weight: bold;
      }
    }
  }
}
