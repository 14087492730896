@import '../utils';

// Nissan brand theme
$nissan-primary-color: #b32535;
$nissan-secondary-color: #fff;
$nissan-screenhead-background: #f4f5f7;
$nissan-body-color: #eeefef;
$nissan-contrast-color: #000;
$nissan-background-color: #f2f2f2;
$nissan-logo: '../../images/nissan_logo.svg';
$nissan-logo-promo: '../../images/nissan_logo_promo.png';

/* BRAND FONTS */

@font-face {
  font-family: 'nissan_brand';
  // IE9 Compat Modes
  src: url('../fonts/nissanbrand-bold-webfont.eot');
  // IE6-IE8
  src: url('../fonts/nissanbrand-bold-webfont.eot?#iefix') format('embedded-opentype'),
    // Super Modern Browsers
    url('../fonts/nissanbrand-bold-webfont.woff2') format('woff2'),
    // Modern Browsers
    url('../fonts/nissanbrand-bold-webfont.woff') format('woff'),
    // Safari, Android, iOS
    url('../fonts/nissanbrand-bold-webfont.ttf') format('truetype'),
    // Legacy iOS
    url('../fonts/nissanbrand-bold-webfont.svg#nissan_brandbold') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'nissan_brand';
  // IE9 Compat Modes
  src: url('../fonts/nissanbrand-bold-webfont.eot');
  // IE6-IE8
  src: url('../fonts/nissanbrand-bold-webfont.eot?#iefix') format('embedded-opentype'),
    // Super Modern Browsers
    url('../fonts/nissanbrand-bold-webfont.woff2') format('woff2'),
    // Modern Browsers
    url('../fonts/nissanbrand-bold-webfont.woff') format('woff'),
    // Safari, Android, iOS
    url('../fonts/nissanbrand-bold-webfont.ttf') format('truetype'),
    // Legacy iOS
    url('../fonts/nissanbrand-bold-webfont.svg#nissan_brandbold') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'nissan_brand';
  // IE9 Compat Modes
  src: url('../fonts/nissanbrand-italic-webfont.eot');
  // IE6-IE8
  src: url('../fonts/nissanbrand-italic-webfont.eot?#iefix') format('embedded-opentype'),
    // Super Modern Browsers
    url('../fonts/nissanbrand-italic-webfont.woff2') format('woff2'),
    // Modern Browsers
    url('../fonts/nissanbrand-italic-webfont.woff') format('woff'),
    // Safari, Android, iOS
    url('../fonts/nissanbrand-italic-webfont.ttf') format('truetype'),
    // Legacy iOS
    url('../fonts/nissanbrand-italic-webfont.svg#nissan_branditalic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'nissan_brand';
  // IE9 Compat Modes
  src: url('../fonts/nissanbrand-light-webfont.eot');
  // IE6-IE8
  src: url('../fonts/nissanbrand-light-webfont.eot?#iefix') format('embedded-opentype'),
    // Super Modern Browsers
    url('../fonts/nissanbrand-light-webfont.woff2') format('woff2'),
    // Modern Browsers
    url('../fonts/nissanbrand-light-webfont.woff') format('woff'),
    // Safari, Android, iOS
    url('../fonts/nissanbrand-light-webfont.ttf') format('truetype'),
    // Legacy iOS
    url('../fonts/nissanbrand-light-webfont.svg#nissan_brandlight') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'nissan_brand';
  // IE9 Compat Modes
  src: url('../fonts/nissanbrand-regular-webfont.eot');
  // IE6-IE8
  src: url('../fonts/nissanbrand-regular-webfont.eot?#iefix') format('embedded-opentype'),
    // Super Modern Browsers
    url('../fonts/nissanbrand-regular-webfont.woff2') format('woff2'),
    // Modern Browsers
    url('../fonts/nissanbrand-regular-webfont.woff') format('woff'),
    // Safari, Android, iOS
    url('../fonts/nissanbrand-regular-webfont.ttf') format('truetype'),
    // Legacy iOS
    url('../fonts/nissanbrand-regular-webfont.svg#nissan_brandregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

// =========================
// Nissan specific styling
// =========================

.expand-image-modal.Nissan {
  font-family: 'nissan_brand', sans-serif;
}

div.nissan {
  & *,
  .Topics .topic-content .note * {
    font-family: 'nissan_brand', sans-serif;
  }

  .topic-content {
    p,
    .p,
    li,
    strong {
      font-family: 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
    }
  }

  // Logo
  .logo {
    background-image: url($nissan-logo-promo);
  }

  .screen-logo,
  .terms-logo {
    background: none;

    &:after {
      content: '';
      display: block;
      background: url($nissan-logo) no-repeat;
      background-size: 100%;
      position: absolute;
      top: rem(5);
      z-index: 1;
    }
  }

  .screen-container,
  .screen-content {
    @include media-mobile() {
      background: $nissan-screenhead-background;
    }
  }

  .screen-logo {
    width: rem(75);
    &:after {
      width: rem(76);
      height: rem(76);
    }
  }

  .terms-logo {
    width: rem(56);
    flex: 0 0 rem(56);
    &:after {
      top: rem(12);
      width: rem(56);
      height: rem(56);
    }
  }

  @include media-desktop {
    .terms-logo {
      width: rem(76);
      flex: 0 0 rem(76);
      align-self: flex-start;

      &:after {
        width: rem(76);
        height: rem(94);
        top: rem(15);
      }
    }
  }

  // Splash screen colors
  .Vehicles,
  .BrandsSelectorScreen,
  .Guide {
    color: $nissan-secondary-color;

    .hint {
      color: #e57593;
    }
    .screen-title {
      color: $nissan-contrast-color;
    }
    .screen-overlay {
      background: $nissan-primary-color;
      color: $nissan-secondary-color;

      .PrimaryButton {
        color: $nissan-contrast-color;
        background: $nissan-secondary-color;
      }

      .icon .fill {
        fill: rgba($nissan-secondary-color, 0.5);
      }
    }

    @include media-mobile() {
      .screen-head {
        box-shadow: none;
      }
      .screen-title,
      .screen-back > * {
        color: $nissan-contrast-color;

        .icon {
          margin-right: rem(20);
        }
      }
      .screen-back .icon .stroke {
        stroke: $nissan-contrast-color;
      }
    }
  }

  // Regular screen colors
  .Guide {
    color: $nissan-contrast-color;
    background: $nissan-secondary-color;
  }

  .Guide,
  .Vehicles {
    .screen-head {
      @include media-mobile() {
        background: $nissan-body-color;
        border-bottom: 3px solid $nissan-contrast-color;
        box-shadow: none;

        .screen-title {
          color: $nissan-contrast-color;
        }
      }
    }
  }

  //loader styling
  .Loader {
    background-color: $nissan-primary-color;

    .loader-default {
      & > div {
        background-color: $nissan-secondary-color;
      }
    }
    .loader-ios {
      @keyframes spinner-ios-nissan {
        0% {
          background-color: $nissan-secondary-color;
        }
        100% {
          background-color: transparent;
        }
      }
      @keyframes spinner-ios-nissan-inverse {
        0% {
          background-color: $nissan-primary-color;
        }
        100% {
          background-color: transparent;
        }
      }
      & > div {
        animation-name: spinner-ios-nissan;
      }
    }

    .loader-android {
      circle {
        stroke: $nissan-secondary-color;
      }
    }

    &.inverse {
      background-color: $nissan-secondary-color;
      color: $nissan-contrast-color;

      @include media-mobile() {
        background-color: transparent;
      }

      .loader-default > div {
        background-color: $nissan-contrast-color;
      }
      .loader-ios > div {
        animation-name: spinner-ios-nissan-inverse;
      }
      .loader-android {
        circle {
          stroke: $nissan-primary-color;
        }
      }
    }
  }

  .VehicleList {
    background: $nissan-secondary-color;
    .model {
      color: $nissan-contrast-color;
      .name {
        color: $nissan-contrast-color;
      }
    }
  }

  .VehicleYearSelector {
    .wrapper > a {
      &.current {
        color: $nissan-contrast-color;

        &:after {
          background: $nissan-primary-color;
        }
      }
    }
  }

  .VehicleModelSelector {
    .model {
      display: flex;
      flex-direction: column;
      margin-bottom: rem(32);
      transition: all 0.3s;
      position: relative;
      padding: 0;

      &:last-child {
        margin-bottom: 0;
      }

      .name {
        font-size: rem(16);
        padding: 0;
        text-decoration: none;
        color: inherit;

        &.name-long {
          font-size: rem(18);
        }

        &.name-line {
          font-size: rem(20);
          white-space: nowrap;
          overflow: hidden;
          width: 100%;
          text-overflow: ellipsis;
        }
      }
    }
    .model .icon.icon-arrow-left .stroke {
      stroke: $nissan-primary-color;
    }
    .back .icon.icon-arrow-left .stroke {
      stroke: #999;
    }
  }

  .VehicleModelSelector {
    .model-container {
      .model-group {
        display: flex;
        align-items: center;
        height: rem(56);
        font-size: rem(18);
        font-weight: 700;
        padding: rem(16) rem(21);
        border-bottom: 1px solid opacify(#d9dee3, 0.5);
      }

      .model-inner {
        flex: 0 0 auto;
        padding: rem(24) rem(9) rem(24) rem(21);
      }

      .close {
        position: absolute;
        top: rem(15);
        right: rem(15);
        border: none;
        width: rem(25);
        height: rem(25);
        cursor: pointer;
        background: transparent;

        &:before,
        &:after {
          content: ' ';
          display: block;
          position: absolute;
          width: rem(17);
          height: rem(2);
          transform: rotate(45deg);
          background: #999999;
          left: rem(4);
          top: 50%;
        }
        &:after {
          transform: rotate(-45deg);
        }
      }
    }
  }

  .VehicleGroupFilter {
    .group > button {
      color: #767676;
    }
    .group.current > button {
      box-shadow: inset 0 -4px $nissan-primary-color;
      color: $nissan-primary-color;
    }
  }

  //Folder filters desktop/mobile
  .FoldersFilter {
    li.active {
      color: $nissan-primary-color;

      .icon .stroke {
        stroke: $nissan-primary-color;
      }
    }
  }
  .FoldersFilterDropdown {
    .dropdown-trigger {
      background-color: $nissan-primary-color;
      color: $nissan-secondary-color;
      box-shadow: inset 0 -1px rgba($nissan-secondary-color, 0.3);

      .icon .stroke {
        stroke: $nissan-secondary-color;
      }
    }
    .dropdown-container {
      background-color: $nissan-primary-color;
      li {
        color: $nissan-secondary-color;
      }
    }
  }

  // Buttons
  .PrimaryButton {
    color: $nissan-contrast-color;
    background: $nissan-secondary-color;
  }
  .SecondaryButton {
    color: $nissan-secondary-color;
    background: $nissan-primary-color;
    border: 1px solid rgba($nissan-secondary-color, 0.3);
    font-weight: 400;
  }

  .ErrorMessage.inverse,
  .screen-overlay {
    .PrimaryButton {
      color: $nissan-secondary-color;
      background: $nissan-primary-color;
    }
  }

  .ErrorMessage:not(.inverse) {
    background-color: $nissan-primary-color;
    color: $nissan-secondary-color;
    .icon .fill {
      fill: rgba($nissan-secondary-color, 0.5);
    }
    h1 {
      color: $nissan-secondary-color;
    }
    span {
      color: rgba($nissan-secondary-color, 0.5);
    }
  }

  .Topics {
    @include media-mobile {
      .topics-subtitle {
        font-weight: 400;
        background: transparent;
        box-shadow: none;
        padding: rem(14) rem(40) rem(14) rem(20);
      }

      .topic-content {
        padding: rem(0) rem(20) rem(20) rem(20);
        margin: 0;
      }
    }
  }

  .TopicsList {
    @include media-mobile() {
      display: flex;
      flex-flow: column;
      flex: 1 0 auto;
      padding: rem(16) rem(18) rem(17);
      background: #f4f5f7;

      li {
        display: flex;
        box-shadow: 0px rem(4) rem(8) rgba(0, 0, 0, 0.04);
        border-radius: rem(4);
        background: #fff;
        margin-bottom: rem(10);

        &:first-child {
          box-shadow: none;
        }

        .item-inner {
          display: flex;
          flex-flow: row nowrap;
          text-decoration: none;
          position: relative;
          flex: 1;
          padding: rem(12) rem(16) rem(12) rem(16);
          align-items: flex-start;

          .item-title {
            flex: 1;
            padding-right: rem(20);
            transition: all 0.2s;
            font-weight: 400;
            font-size: rem(16);
            line-height: rem(24);
            color: #000;
            letter-spacing: -0.31px;

            &.return {
              font-weight: 300;
            }
          }
        }
      }
    }

    &.publications {
      @include media-mobile() {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-evenly;
        align-items: flex-start;
        align-content: start;

        li {
          height: rem(135);
          max-width: 45%;
          flex: 1 0 45%;
          margin-bottom: rem(15);

          &:nth-child(odd) {
            margin-right: rem(15);
          }

          .item-inner {
            flex-flow: column-reverse;
            justify-content: space-evenly;
            align-items: center;

            .item-title {
              flex: 0 1 auto;
              padding: 0;
              text-align: center;
              line-height: rem(22);
              opacity: 0.8;

              // handle long names
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }

          .item-iconbar .icon {
            width: rem(40);
            height: auto;
            margin: 0;

            &.icon-folder {
              width: rem(35);
            }
          }
        }
      }
    }
  }

  .SearchInput {
    @include media-mobile {
      & + .topics-subtitle {
        background-color: transparent !important;
        box-shadow: none;
        padding: rem(18) rem(18) rem(10) rem(20);
        font-weight: 400;
        line-height: rem(24);

        hr {
          width: 100%;
          height: 1px;
          border: 0;
          background: rgba(0, 0, 0, 0.1);
          margin: 0;
          margin-top: rem(17);
        }
      }

      .SearchInput__icon-post {
        display: none;
      }

      .SearchInput__icon-pre {
        display: block;
      }

      input {
        font-size: rem(16);
        margin: 0;
        padding: 0;
      }
    }
  }

  .Explore {
    .visual-search {
      .hot-spot {
        background-color: #b32535cc;
      }
    }
  }
}
