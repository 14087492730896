@import '../../styles/utils';

.expand-image-modal {
  position: absolute;
  inset: 10%;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: visible;
  border-radius: 4px;
  outline: none;
  padding: 20px;

  @include media-mobile() {
    inset: 0;
  }

  .image-container-modal {
    position: relative;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: auto;
      max-height: 100%;
      object-fit: contain;
    }
  }

  .close-btn {
    position: absolute;
    top: -45px;
    right: 0;
    color: #fff;
    cursor: pointer;
    font-family: inherit;

    display: flex;
    align-items: center;

    @include media-mobile() {
      color: #000;
      left: 0;
      top: 0;
      padding: 20px;
    }

    .icon-close {
      width: rem(24);
      margin-right: 10px;
    }
  }
}
