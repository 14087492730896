// Infiniti brand theme
$infiniti-primary-color: #020b24;
$infiniti-secondary-color: #fff;

// functional colors
$infiniti-dark-gray: #646464;
$infiniti-gray: #b4b4b4;
$infiniti-light-gray: #e7e7e7;
$infiniti-gold: #efede9;

$infiniti-signal-error: #d0573a;

$infiniti-regular-color: #7c8d98;
$infiniti-contrast-color: #000;
$infiniti-background-color: #f2f2f2;
