.PdfPreview {
  $previewBackgroundColor: #6e6e6e;

  background-color: $previewBackgroundColor;

  height: calc(100% - 60px);

  &__Page {
    margin: 24px 0;
    line-height: 1;

    .react-pdf__Page__canvas {
      margin-left: auto;
      margin-right: auto;
    }

    &-inner-container {
      margin: 12px auto;
    }
  }

  &-page {
    background-color: $previewBackgroundColor;
  }

  &.react-pdf__Document {
    overflow: scroll;

    .Loader {
      background-color: $previewBackgroundColor;
    }
  }

  .react-pdf__message {
    color: #fff;
    text-align: center;
    margin-top: 24px;
  }

  .highlight {
    color: transparent;
    opacity: 0.15;
    background-color: #ff0;
  }

  .popupAnnotation {
    display: none;
  }
}
