@import 'common';

.Vehicles {
  @extend %Screen;

  padding: 0 rem(75) 0 rem(115);

  @include media-mobile() {
    padding: 0 rem(20);
  }
}
