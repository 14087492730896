@import '../../styles/common';

.SearchInput {
  display: flex;
  flex-shrink: 0;
  padding: rem(15) 0;
  align-items: center;
  box-shadow: inset 0 -1px #e2e2e2;
  box-shadow: inset 0 -1px #e2e2e2;

  .SearchInput__icon-pre {
    margin-right: rem(18);
    display: block;
  }

  .SearchInput__icon-post {
    display: none;
  }

  & + .topics-subtitle {
    font-weight: 700;
  }

  @include media-desktop() {
    & + .topics-subtitle {
      hr {
        display: none;
      }
    }
  }

  @include media-mobile {
    padding: rem(14) rem(10) rem(14) rem(20);

    & + .topics-subtitle {
      background-color: transparent !important;
    }

    .SearchInput__icon-post {
      display: block;
    }
  }

  input {
    flex: 1;
    font-size: rem(20);
    color: #000;
    background: transparent;
    border: none;
    outline: none;
    height: rem(34);
    line-height: rem(34);

    &::placeholder {
      color: #5e5757;
      opacity: 1;
    }

    @include media-mobile {
      font-size: rem(18);
    }
  }

  .icon.icon-search {
    width: rem(17);
    height: rem(18);
    margin-right: rem(18);

    @include media-mobile() {
      margin-right: rem(9);
    }

    .stroke {
      stroke: #000;
    }
  }

  .icon-close {
    cursor: pointer;
    padding: rem(10);
    width: rem(34);
  }
}
