@import '../../styles/common';

.Explore {
  @extend %Screen;

  &.Topics .topics-breadcrumb {
    margin-bottom: 0;
  }

  .visual-search-wrapper {
    display: flex;

    @include media-desktop() {
      .TopicsList li {
        margin-bottom: rem(15);
      }
    }

    @include media-mobile() {
      flex: 1 0 auto;
      flex-flow: column;
    }

    .topics-controls {
      display: flex;
      margin-top: auto;

      @include media-mobile() {
        padding: rem(15) 0 0 rem(20);
      }

      .topics-controls__prev {
        margin-right: rem(8);
      }

      .topics-controls__next {
        transform: rotate(180deg);
      }

      .icon {
        display: flex;
        width: rem(32);
        height: rem(32);
        padding: rem(9) rem(12);
        background-color: #f1f1f1;
        cursor: pointer;

        &:not(.active) {
          cursor: default;
          opacity: 0.4;
        }

        .stroke {
          stroke: #000;
        }
      }
    }
  }

  .visual-search {
    display: flex;

    .image-map-container {
      width: 100%;
      position: relative;

      .hot-spot {
        position: absolute;
        display: block;
        cursor: pointer;
        opacity: 0.6;
        transition: all 0.2s;
        &.active {
          animation: jumpingSpot 1.3s infinite;
          opacity: 0.8;
        }

        @include media-desktop() {
          width: rem(40);
          height: rem(40);
          margin-top: rem(-20);
          margin-left: rem(-20);
          border-radius: rem(20);
          &.active {
            width: rem(60);
            height: rem(60);
            margin-top: rem(-30);
            margin-left: rem(-30);
            border-radius: rem(30);
          }
        }

        @include media-mobile() {
          width: rem(40);
          height: rem(40);
          margin-top: rem(-20);
          margin-left: rem(-20);
          border-radius: rem(20);
          &.active {
            width: rem(60);
            height: rem(60);
            margin-top: rem(-30);
            margin-left: rem(-30);
            border-radius: rem(30);
          }
        }
      }

      .image-map {
        position: relative;
        user-select: none;
        overflow: hidden;
        //-webkit-overflow-scrolling: touch;

        @include media-desktop {
          overflow: hidden;
        }

        &:focus {
          outline-style: none;
          box-shadow: none;
          border-color: transparent;
        }

        .image-map-inner {
          display: block;
          width: 100%;

          transform-origin: 0 0;

          img {
            display: block;
            width: 100%;
          }
        }

        .image-map-overlay {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: space-around;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgba(#000, 0.5);
          pointer-events: none;
          transition: 0.2s all;

          &.hidden {
            opacity: 0;
            visibility: hidden;
          }

          .image-map-gesture {
            color: #fff;
            font-size: rem(14);
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
          }

          .icon {
            width: rem(75);

            .fill {
              fill: #191919;
            }

            .stroke {
              stroke: #191919;
            }

            &.icon-gesture-pinch {
              animation: pinchIcon 2s infinite;
            }
            &.icon-gesture-pan {
              animation: panIcon 2s infinite;
            }
          }
        }

        &.zoom {
          //@include NoScrollbar;
          //overflow: scroll;

          &.zoom-2x .hot-spot {
            transform: scale(0.8);

            &.active {
              transform: scale(0.7);

              animation-name: jumpingSpotZoom2x;
            }
          }
          &.zoom-3x .hot-spot {
            transform: scale(0.7);

            &.active {
              transform: scale(0.6);

              animation-name: jumpingSpotZoom3x;
            }
          }
        }
      }
      .image-map-zoom {
        display: none;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: rem(50);
        height: rem(50);
        bottom: rem(100);
        right: rem(10);
        background: white;
        border-radius: 100%;
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);

        .icon {
          width: rem(20);
          height: rem(20);
          .fill {
            fill: #010101;
          }
        }

        @include media-mobile {
          display: flex;
        }
      }

      .image-map-selector {
        display: flex;
        align-items: stretch;
        flex-direction: row;
        position: relative;
        max-height: rem(85);

        @include media-desktop() {
          max-height: rem(115);
        }

        .button-name {
          color: #191919;
          font-size: rem(12);
          font-weight: 400;
          line-height: rem(14);

          @include media-desktop() {
            font-size: rem(18);
          }
        }

        button {
          display: flex;
          flex-flow: column;
          align-items: center;
          justify-content: center;
          flex: 1 1 0;
          text-align: center;
          cursor: pointer;
          padding: 3% 1%;
          margin: 0;
          background-position: center;
          background-repeat: no-repeat;
          background-color: #fff;

          border: 1px solid rgba(0, 0, 0, 0.1);
          border-left: none;

          @include media-desktop() {
            height: rem(115);
          }

          &:first-child {
            border-left: 1px solid rgba(0, 0, 0, 0.1);
          }

          .icon {
            flex: 1 1 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 6%;
            width: rem(40);

            @include media-desktop() {
              padding: 0;
            }

            &.icon-explore360 {
              width: rem(60);
            }

            // TODO: remove when proper icons provided
            &.icon-exploreHatch,
            &.icon-exploreBack,
            &.icon-explorePickup {
              width: rem(65);
            }

            .stroke {
              stroke: #000;
            }

            .fill {
              fill: #000;
            }
          }

          &.active {
            background-color: #191919;
            border-top: 1px solid #191919;
            border-right: 1px solid #191919;

            .button-name {
              color: #fff;
            }

            .icon {
              .stroke {
                stroke: #fff;
              }

              .fill {
                fill: #fff;
              }
            }
          }
        }
      }
    }
  }

  .topics {
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
  }

  .manuals {
    margin-top: rem(60);

    @include media-mobile() {
      h2 {
        padding-left: 20px;
      }
    }
  }

  .SearchInput {
    box-shadow: none;

    .icon-close {
      display: none;
    }
  }

  @include media-desktop() {
    .screen-container {
      display: flex;
      flex-direction: column;

      .visualSearch {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 50%;
      }
      .topics {
        padding-left: rem(60);
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 50%;
      }
    }
  }

  h2 {
    font-size: rem(18);
    line-height: rem(25);
    font-weight: 700;
    color: #000;
    margin-bottom: rem(30);
    text-transform: uppercase;
  }
}

@keyframes jumpingSpot {
  from {
    transform: scale(0.95);
  }
  50% {
    transform: scale(1.05);
  }
  to {
    transform: scale(0.95);
  }
}

@keyframes jumpingSpotZoom2x {
  from {
    transform: scale(0.68);
  }
  50% {
    transform: scale(0.72);
  }
  to {
    transform: scale(0.68);
  }
}

@keyframes jumpingSpotZoom3x {
  from {
    transform: scale(0.58);
  }
  50% {
    transform: scale(0.62);
  }
  to {
    transform: scale(0.58);
  }
}

@keyframes pinchIcon {
  from {
    transform: scale(1) rotate(-5deg);
    opacity: 0.9;
  }
  50% {
    transform: scale(1.1) rotate(5deg);
    opacity: 1;
  }
  to {
    transform: scale(1) rotate(-5deg);
    opacity: 0.9;
  }
}

@keyframes panIcon {
  0% {
    transform: translate(0, 0);
    opacity: 0.9;
  }
  50% {
    transform: translate(rem(7), rem(3));
    opacity: 1;
  }
  100% {
    transform: translate(0, 0);
    opacity: 0.9;
  }
}
